<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                PROFIL DZIAŁALNOŚCI
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                DOŚWIADCZENIE
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <!-- <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title> -->
                  <v-card-text>
                    Produkty i rozwiązania oferowane przez POLGRID mają unikalny charakter. Skupiają się na bezpiecznych inwestycjach oferujących naturalny hedging dla rosnących cen energii.
                    POLGRID SA to pierwszy polski podmiot prywatny budujący sieci energetyczne, który łączy doświadczenie w profesjonalnej energetyce przemysłowej z tworzeniem i wdrażaniem produktów inwestycyjnych w oparciu o projekty infrastrukturalne. Spółka skupia się na budowie infrastruktury energetycznej oraz na wdrażaniu w pełnym zakresie projektów energetyki odnawialnej OZE w postaci farm PV, farm wiatrowych, magazynów energii i innych alternatywnych źródeł energii w oparciu o dedykowane wehikuły inwestycyjne. <br>
                    Produkty i rozwiązania oferowane przez POLGRID mają unikalny charakter. Skupiają się na bezpiecznych inwestycjach poprzez niwelowanie zagrożeń na rynku mocy i stabilizacji sieci energetycznych oferując naturalny hedging dla rosnących cen energii. <br>
                    Połączenie wysokich kompetencji w branży energetycznej i finansowej pozwala zaproponować bezpieczne produkty inwestycyjne.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                MISJA I WIZJA
              </h2>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title>
                  <v-card-text>
                    Połączenie wysokich kompetencji w branży energetycznej i finansowej pozwala na tworzenie i wdrażanie bezpiecznych produktów inwestycyjnych opartych na projektach infrastrukturalnych w zakresie energetyki odnawialnej OZE takich jak farmy PV, farmy wiatrowe, magazyny energii oraz inne zarówno tradycyjne jak i alternatywne źródła energii. Strategia rozwoju zakłada zbudowanie portfela projektów energetycznych o łącznej mocy 1,6 GW.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-spacer />
              <v-col
                md="5"
                offest-md="1"
                sm="12"
              >
                <v-card
                  outlined
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-img src="/static/e2.jpg" />
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                NASZE WARTOŚCI
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                PIERWSZY POLSKI PODMIOT PRYWATNY BUDUJĄCY SIECI ENERGETYCZNE
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    POLGRID SA to pierwszy polski podmiot prywatny budujący sieci energetyczne, który łączy doświadczenie w profesjonalnej energetyce przemysłowej z tworzeniem i wdrażaniem produktów inwestycyjnych w oparciu o projekty infrastrukturalne. Działania operacyjne Spółki wpisują się w globalny trend rozwoju OZE.
                    Połączenie wysokich kompetencji w branży energetycznej i finansowej pozwala zaproponować bezpieczne produkty inwestycyjne, których mamy obecnie deficyt na rynku.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                ZARZĄD
              </h2>
            </v-col>
            <v-container
              class="d-md-inline-flex"
              style="font-family:'Kanit'!important"
            >
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/baczkowskipawel.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Paweł Bączkowski
                </p>
                <p style="font-size:0.75rem">
                  PREZES ZARZĄDU
                </p>
              </v-col>
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/sawickitomasz.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Tomasz Sawicki
                </p>
                <p style="font-size:0.75rem">
                  WICEPREZES ZARZĄDU
                </p>
              </v-col>
              <!-- <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/jenkinsadam.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Adam Jenkins
                </p>
                <p style="font-size:0.75rem">
                  WICEPREZES ZARZĄDU
                </p>
              </v-col> -->
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5 background-light-grey">
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                RADA NADZORCZA
              </h2>
            </v-col>
            <v-container
              class="d-md-inline-flex"
              style="font-family:'Kanit'!important"
            >
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/kapinskaewa.jpeg">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Ewa Kapińska
                </p>
                <p style="font-size:0.75rem">
                  PRZEWODNICZĄCA RADY NADZORCZEJ
                </p>
              </v-col>
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/lozinskimarcin.jpg">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Marcin Łoziński
                </p>
                <p style="font-size:0.75rem">
                  CZŁONEK RADY NADZORCZEJ
                </p>
              </v-col>
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/marcinkowskislawomir.jpeg">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Sławomir Marcinkowski
                </p>
                <p style="font-size:0.75rem">
                  CZŁONEK RADY NADZORCZEJ
                </p>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                DORADCY
              </h2>
            </v-col>
            <v-container
              class="d-md-inline-flex"
              style="font-family:'Kanit'!important"
            >
              <!-- <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/pietrzakp.jpeg">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Piotr Pietrzak
                </p>
                <p style="font-size:0.75rem">
                  DORADCA ZARZĄDU DS. STRATEGII
                </p>
              </v-col> -->
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/dlugoleckimarcin.png">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Marcin Długołęcki
                </p>
                <p style="font-size:0.75rem">
                  DORADCA ZARZĄDU DS. ROZWOJU
                </p>
              </v-col>
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-avatar
                  size="200"
                  class="mb-5"
                >
                  <img src="/static/chojnackikrzysztof.jpeg">
                </v-avatar>
                <hr>
                <p class="mt-5">
                  Krzysztof Chojnacki
                </p>
                <p style="font-size:0.75rem">
                  SPECJALISTA DS. ROZWOJU SIECI
                </p>
                <p style="font-size:0.65rem;font-style:italic;color:#777777;">
                  ENERGETYKA WAGON SP. Z O.O.
                </p>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                DANE SPÓŁEK
              </h2>
              <!-- <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                DOŚWIADCZENIE
              </p> -->
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    POLGRID S.A.
                  </v-card-title>
                  <v-card-text>
                    z siedzibą w Warszawie, Centrum LIM, 17 piętro, al. Jerozolimskie 65/79 00-697 Warszawa; NIP 5272747738, REGON 362787442. Zarejestrowana w Rejestrze Przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000580980 przez Sąd Rejonowy dla Miasta Stołecznego Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    POLGRID SERWIS sp. z o.o.
                  </v-card-title>
                  <v-card-text>
                    z siedzibą w Warszawie ul. Kruszyńska 30b, 01-365 Warszawa; NIP 5223021065, REGON 360212401. Zarejestrowana w Rejestrze Przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000531387 przez Sąd Rejonowy dla Miasta Stołecznego Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
